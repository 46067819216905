<template>
  <AtroButton
    v-if="show"
    type="fill"
    icon-left="chevron-left"
    :class="{ '!py-3': $isMobile.value }"
    :size="$isMobile.value ? 'md' : 'sm'"
    :text="buttonText"
    @click="onClick"
  />
</template>

<script setup lang="ts">
const router = useRouter()
const isMobile = useIsMobile()
const { goBackToDashboard } = useHomeNav()
const { isKioskMode } = useCurrentOrg()
const { isPartner } = useCurrentUser()

const show = computed(() => !(isKioskMode.value && !isPartner.value))
const lastRoute = computed(() => router.options.history.state.back?.toString())

const lastRouteName = computed(() => {
  if (lastRoute.value?.includes('/dashboard')) return 'orgs-slug-dashboard'
  if (lastRoute.value?.endsWith('/score')) return 'orgs-slug-score'
  if (lastRoute.value?.endsWith('/team')) return 'orgs-slug-team'
})

const buttonText = computed(() => {
  if (isMobile.value) return undefined

  if (lastRouteName.value === 'orgs-slug-dashboard') {
    return 'Partner Dashboard'
  } else if (lastRouteName.value === 'orgs-slug-score') {
    return 'Score Breakdown'
  } else if (lastRouteName.value === 'orgs-slug-team') {
    return 'Team Overview'
  } else {
    return 'Dashboard'
  }
})

function onClick() {
  if (lastRouteName.value) {
    router.back()
  } else {
    goBackToDashboard()
  }
}
</script>
